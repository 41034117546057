import Link from "next/link";
import BiLogoWhite from "../public/img/bi.svg";

const Footer = () => {
  return (
    <footer className="w-screen mt-auto footer-container relative flex flex-col items-center py-10">
      <div className="w-11/12">
        <BiLogoWhite width="126" />
      </div>
      <div className="w-11/12">
        <p className="text-sm text-gray-400 font-light my-5 leading-none">
          The information contained on this website is the responsibility of the
          individuals or corporations that provide it, so Boehringer Ingelheim
          or any of its related companies will not be liable for any misuse of
          the same by the user; Likewise, the user declares to have read,
          understood and accepted the Privacy Notice that is available in the
          Application and on the Website.
        </p>

        {/* <p className="mb-4 text-sm text-gray-400">PH: PC-MX-110092</p> */}
      </div>

      <div className="w-11/12 flex flex-col lg:flex-row justify-between items-center">
        <div className="flex justify-start items-center w-full lg:w-3/6">
          <p className="text-xs text-gray-400 font-light">
            <a
              target="_blank"
              href="/pdf/PRIVACY_NOTICE_HCPs_ENGLISH_MAR_3_2021.pdf"
              className="uppercase cursor-pointer transition ease-in duration-100 hover:text-deep-sky-blue"
            >
              Privacy notice
            </a>{" "}
            |{" "}
            <a
              target="_blank"
              href="/pdf/TERMINOS_Y_CONDICIONES_BOEHRINGER_CLICK_AGO_26_2021_EN-US.pdf"
              className="cursor-pointer transition ease-in duration-100 hover:text-deep-sky-blue uppercase"
            >
              Terms of use and conditions
            </a>{" "}
            |{" "}
            <Link
              href="/contact"
              className="cursor-pointer transition ease-in duration-100 hover:text-deep-sky-blue uppercase"
            >
              Contact
            </Link>
          </p>
        </div>
        <div className="w-full lg:w-1/4 mt-5 lg:mt-0">
          <p className="text-xs text-gray-400 font-light text-center lg:text-right">
            &#169; 2021{" "}
            <span className="text-deep-sky-blue cursor-pointer hover:underline">
              <a target="_blank" href="https://www.boehringer-ingelheim.mx/">
                {" "}
                Boehringer Ingelheim México
              </a>
            </span>{" "}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
