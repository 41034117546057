import { useState, useEffect, useRef } from 'react'
import Link from 'next/link';
import Menu from '../public/img/menu.svg';
import MenuDesktop from '../public/img/menuDesktop.svg';
import Search from '../public/img/search.svg';
import Close from '../public/img/close.svg';
import LeftChevronIcon from '../public/img/chevron-left-solid.svg';
import ModalSearch from '../components/modals/ModalSearch';
import LiveButton from '../components/liveButton'
import { Transition } from '@headlessui/react';
import { getComponent } from '../src/utils.js'
import api from '../src/api/'
import { useRouter } from 'next/router'
import algoliasearch from 'algoliasearch/lite'
import { useClickAway } from 'react-use'

export default function Header({ children, session, liveEvents }) {

  const [searchIconState, setSearchIconState] = useState(true)
  const [searchBarState, setSearchBarState] = useState(false)
  const [modalSearchState, setModalSearchState] = useState(false)
  const [searchState, setSearchState] = useState('')
  const [path, setPath] = useState('');
  const [isOn, setIsOn] = useState(false);

  const [adminApiKey, setAdminApiKey] = useState('')

  const algoliaClient = algoliasearch('AGQEK8RMM2', adminApiKey)

  const router = useRouter()

  const ref = useRef(null)

  useClickAway(ref, () => {
    const screenWidth = window.innerWidth
    if(screenWidth < 1024) return
    setTimeout(() => {setIsOn(false)}, 200)
  })

  const toggleMenu = () => {
    setIsOn(!isOn);
  };

  const closeModal = () => {
    console.log('search clicked -> ', )
    setModalSearchState(false)
    //document.body.style.overflow = 'auto'
  }

  // const openModal = () => {
  //   setModalSearchState(true)
  //   //document.body.style.overflow = 'hidden'
  // }

  // const handleChange = async e => {
  //   let searchValue = e.target.value

  //   // setSearchState(searchValue);

  //   setSearchState(searchValue);

  //   if (searchValue.length > 0) {
  //     openModal(true);
  //     setSearchIconState(false);
  //   } else {
  //     closeModal();
  //     setSearchIconState(true);
  //   }
  // }

  const toggleSearchIcon = () => {
    setSearchState('');
    closeModal();
    setSearchIconState(true);
    setSearchBarState(false);
    document.body.style.overflow = 'auto';
  };

  const toggleSearchBar = () => {
    setSearchBarState(true);
  };

  useEffect(() => {

    setPath(window.location.pathname)

    if (modalSearchState)
      document.body.style.overflow = 'hidden'

    if (!modalSearchState)
      document.body.style.overflow = 'auto'

  }, [modalSearchState])


  const getAdminApiKey = async () => {
    const adminApiKey = await new api.GetAdminApiKey().doQuery(null, session.user?.image?.access_token)
    setAdminApiKey(adminApiKey)
  }

  useEffect(() => {
    getAdminApiKey()
  }, [])

  const handleFocus = event => {
    event.target.removeAttribute('readonly');
    console.log(event.target.autocomplete);
  }

  return <>
    <header className="relative w-full top-0 z-50">
      <nav className="bg-navy fixed w-full">

        {
          (!!liveEvents.length && router.asPath === '/home') &&
          <LiveButton liveEvents={liveEvents} />
        }

        <div className="px-2 sm:px-4 lg:px-8">
          <div className="relative flex items-center justify-between h-16 relative">
            <div className="flex items-shrink px-2 lg:px-0">
              <div className="cursor-pointer z-9 flex items-center">
                {!searchBarState && (
                  <button onClick={toggleMenu}>
                    <Menu className="lg:hidden" />
                  </button>
                )}

                {searchBarState && (
                  <LeftChevronIcon
                    className="block lg:hidden h-8 w-auto"
                    style={{ fill: '#FFFFFF' }}
                    onClick={toggleSearchIcon}
                  />
                )}

                <Link href="/" legacyBehavior>
                  <img
                    className="hidden lg:block h-8 w-auto cursor-pointer"
                    src="/img/bi-click-w.svg"
                    alt="BI Click"
                  />
                </Link>
              </div>

              <div className="hidden lg:flex items-center justify-center lg:ml-6">
                <div className="flex items-center space-x-4">
                  <Link href="/" legacyBehavior>
                    <span
                      className={`px-3 py-2 rounded-md text-sm font-medium cursor-pointer ${path === '/home' ? 'text-white' : 'text-gray-400'
                        }`}
                    >
                      Home
                    </span>
                  </Link>
                  <Link href="/my-account" legacyBehavior>
                    <span className={`px-3 py-2 rounded-md text-sm font-medium cursor-pointer hover:text-white  ${path === '/mis-videos'
                      ? 'text-white'
                      : 'text-gray-400'
                      }`}>
                     My Account 
                    </span>
                  </Link>




                </div>
              </div>
            </div>
            <div className={`flex-1 w-9/12 flex justify-center px-2 lg:justify-start lg:w-full lg:ml-40 ${!!liveEvents.length && router.asPath === '/home' && false ? 'lg:ml-14' : 'lg:ml-40'}`}>
              <div className="hidden lg:block max-w-lg w-full lg:max-w-xs z-20">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="">

                  <ModalSearch algoliaClient={algoliaClient} searchState={searchState} closeModal={closeModal} modalSearchState={modalSearchState} setModalSearchState={setModalSearchState} />

                  {/* <div
                    className="absolute inset-y-0 right-5 pl-3 flex items-center cursor-pointer"
                    onClick={toggleSearchIcon}>
                    {!searchIconState && (
                      <Close className="h-5 w-5 text-gray-400 cursor-pointer" />
                    )}
                  </div> */}
                </div>
              </div>
              {searchBarState && (
                <div className="lg:hidden block max-w-lg w-full lg:max-w-xs z-20">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="">

                    <ModalSearch algoliaClient={algoliaClient} searchState={searchState} closeModal={closeModal} modalSearchState={modalSearchState} setModalSearchState={setModalSearchState} />

                    {/* <input
                      id="search"
                      name="search"
                      className="block w-full pl-5 pr-3 py-2 border border-transparent leading-5 bg-gray-100 text-gray-300 placeholder-gray-400 focus:outline-none focus:bg-white focus:border-white focus:ring-white focus:text-gray-900 sm:text-sm rounded-full"
                      placeholder="Buscar"
                      type="text"
                      value={searchState}
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                    <div className="absolute inset-y-0 right-5 pl-3 flex items-center pointer-events-none">
                      {searchIconState && (
                        <Search className="h-5 w-5 text-gray-400 cursor-pointer" />
                      )}
                    </div>
                    <div
                      className="absolute inset-y-0 right-5 pl-3 flex items-center cursor-pointer"
                      onClick={toggleSearchIcon}
                    >
                      {!searchIconState && (
                        <Close className="h-5 w-5 text-gray-400 cursor-pointer" />
                      )}
                    </div> */}
                  </div>
                </div>
              )}

              {!searchBarState && (
                <Link href="/" legacyBehavior>
                  <img
                    className={`block lg:hidden w-auto cursor-pointer ${(!!liveEvents.length && router.asPath === '/home') ? 'w-auto h-5.5 absolute left-12 top-1/2 transform -translate-y-2/4 right-10 sm:h-6 md:h-8' : 'h-8' }`}
                    src="/img/bi-click-w.svg"
                    alt="Bi logo"
                  />
                </Link>
              )}
            </div>
            <div className="flex lg:hidden">
              {/* <!-- Mobile menu button --> */}
              <button
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>

                <Search
                  className="block h-6 w-6 text-gray-100"
                  onClick={toggleSearchBar}
                />

                <Close className="hidden h-6 w-6 ml-10" />
              </button>
            </div>

            <div className="hidden lg:block lg:ml-4">
              <div className="flex items-center">
                <div className="ml-4 relative flex-shrink-0">
                  <div>
                    <button
                      className="bg-gray-800 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                      ref={ref}
                      id="menu"
                      onClick={toggleMenu}
                      aria-haspopup="true"
                    >
                      <span className="sr-only">Open menu</span>

                      <MenuDesktop />
                    </button>
                  </div>

                  <Transition
                    show={isOn}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    {getComponent(children, 'DropdownPanelDesktop')}
                  </Transition>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Transition
          show={isOn}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          {getComponent(children, 'DropdownPanelMobile')}
        </Transition>
      </nav>
    </header>
  </>;
}
